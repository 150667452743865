<template>
    <div>
        <div v-if="requestCalls > 0">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-body text-center">
                        <skeleton-loading></skeleton-loading>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--7">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col text-center">
                                <h2 class="mb-0">
                                    <b>
                                        {{ tt('advance_search_material') }}
                                    </b>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-if="authUserPermission['workbench-view']">
                        <div class="row">
                            <div class="col-md-4">

                                <!-- MATERIAL NUMBER -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.material_number"
                                    remote filterable reserve-keyword
                                    :placeholder="tt('material_number')"
                                    :remote-method="queryMaterialNumber" 
                                    :loading="selectSearch.smnLoading"
                                    :automatic-dropdown="false" 
                                    clearable>
                                    <el-option v-for="item in optionMaterialNumber" :key="item.material_number" :label="item.material_number" :value="item.material_number"></el-option>
                                </el-select>

                                <!-- MCR NUMBER -->
                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.mcr_number" :placeholder="tt('mcr_number')"
                                    size="medium"></el-input>

                                <!-- ITEM NAME -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.item_name"
                                    remote filterable reserve-keyword
                                    :placeholder="tt('item_name') + ' - ' + tt('minimum_type_char', { total: 3 })"
                                    :remote-method="queryItemName" 
                                    :loading="selectSearch.sitLoading"
                                    :automatic-dropdown="false" 
                                    clearable>
                                    <el-option v-for="item in optionItem" :key="item.inc" :label="item.inc + ' - ' + item.item_name.toUpperCase()" :value="item.inc"></el-option>
                                </el-select>

                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.item_type"
                                    filterable remote reserve-keyword :placeholder="tt('item_type')"
                                    :automatic-dropdown="false" clearable>
                                    <el-option :label="tt('gen')" :value="tt('gen')">
                                    </el-option>
                                    <el-option :label="tt('oem')" :value="tt('oem')">
                                    </el-option>
                                    <el-option :label="tt('gen_oem')" :value="tt('gen_oem')">
                                    </el-option>
                                    <el-option :label="tt('serv')" :value="tt('serv')">
                                    </el-option>
                                </el-select>

                                <!--  GROUP CLASS -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.group_class"
                                    remote filterable reserve-keyword 
                                    :placeholder="tt('nato_group_class')"
                                    :remote-method="queryGroupClass" 
                                    :loading="selectSearch.sgcLoading" 
                                    :automatic-dropdown="false"
                                    clearable>
                                    <el-option v-for="item in optionGroupClass" :key="item.nsc" :label="item.nsc + ' - ' + item.description" :value="item.nsc"></el-option>
                                </el-select>

                                <!-- MATERIAL TYPE -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.material_type"
                                    filterable remote reserve-keyword :placeholder="tt('material_type')"
                                    :loading="selectSearch.smtLoading" 
                                    :remote-method="queryMaterialType" 
                                    :automatic-dropdown="false"
                                    clearable>
                                    <el-option v-for="item in optionMaterialType" :key="item.code" :label="item.code + ' - ' + item.description" :value="item.code"></el-option>
                                </el-select>

                                <!-- MATERIAL GROUP -->
                                <el-select class="form-control-sm col-md-12 mb-4" v-model="data.search.material_group"
                                    filterable remote reserve-keyword :placeholder="tt('material_group')" :loading="selectSearch.smgLoading" :remote-method="queryMaterialGroup" :automatic-dropdown="false" clearable>
                                    <el-option v-for="item in optionMaterialGroup" :key="item.code" :value="item.code" :label="item.code + ' - ' + item.description">
                                    </el-option>
                                </el-select>

                                <!-- UOM -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.uom" filterable
                                    remote reserve-keyword :placeholder="tt('uom')" :loading="selectSearch.suLoading" :automatic-dropdown="false" :remote-method="queryUnitOfMeasure" clearable size="medium">
                                    <el-option v-for="item in optionUnitOfMeasure" :key="item.code" :value="item.code" :label="item.code + ' - ' + item.description">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-md-4">
                                <!--  MANUFACTURE CODE -->
                                <el-select class="form-control-sm col-md-12 mb-4" v-model="data.search.manufacture" filterable remote reserve-keyword :placeholder="tt('manufacture_code')" :loading="selectSearch.smcLoading" :automatic-dropdown="false" :remote-method="queryManufactureCode" clearable>
                                    <el-option v-for="item in optionManufactureCode" :key="item.manufacture_code" :value="item.manufacture_code" :label="item.manufacture_code + ' - ' + item.manufacture_name">
                                    </el-option>
                                </el-select>

                                <!--  MANUFACTURE REFF -->
                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.manufacture_ref" :placeholder="tt('manufacture_refference')"
                                    size="medium" clearable></el-input>

                                <!-- COMPANY -->
                                <el-select class="form-control-sm col-md-12 mb-4" v-model="data.search.company" filterable remote reserve-keyword :placeholder="tt('company')" :loading="selectSearch.scmLoading" :automatic-dropdown="false" :remote-method="queryCompany" clearable>
                                    <el-option v-for="item in optionCompany" :key="item.company_code" :value="item.company_code" :label="item.company_code + ' - ' + item.company_description">
                                    </el-option>
                                </el-select>

                                <!-- PLANT -->
                                <el-select class="form-control-sm col-md-12 mb-4" v-model="data.search.plant" filterable remote reserve-keyword :placeholder="tt('plant')" :loading="selectSearch.splLoading" :automatic-dropdown="false" :remote-method="queryPlant" clearable>
                                    <el-option v-for="item in optionPlant" :key="item.plant_code" :value="item.plant_code" :label="item.plant_code + ' - ' + item.description">
                                    </el-option>
                                </el-select>

                                <!-- Location -->
                                <el-select class="form-control-sm col-md-12 mb-4" v-model="data.search.location" filterable remote reserve-keyword :placeholder="tt('location')" :loading="selectSearch.slcLoading" :automatic-dropdown="false" :remote-method="queryLocation" clearable>
                                    <el-option v-for="item in optionLocation" :key="item.location_code" :value="item.location_code" :label="item.location_code + ' - ' + item.location_description">
                                    </el-option>
                                </el-select>

                                <!-- Equipment Code -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.equipment_code"
                                    filterable remote reserve-keyword :placeholder="tt('equipment_code')" :loading="selectSearch.seqLoading" :automatic-dropdown="false" :remote-method="queryEquipmentCode"
                                    clearable>
                                    <el-option v-for="item in optionEquipmentCode" :key="item.equipment_code" :value="item.equipment_code" :label="item.equipment_code + ' - ' + item.equipment_name">
                                    </el-option>
                                </el-select>

                                <!-- FABRICATION NON FABRICATION -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.fab_non_fab"
                                    :placeholder="tt('fab_non_fab')" :loading="loading" :automatic-dropdown="false"
                                    clearable>
                                    <el-option value="Fabrication" label="Fabrication"></el-option>
                                    <el-option value="Non Fabrication" label="Non Fabrication"></el-option>
                                </el-select>

                                <!-- BOM NON BOM -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.bom_non_bom"
                                    :placeholder="tt('bom_non_bom')" :loading="loading" :automatic-dropdown="false"
                                    clearable>
                                    <el-option value="BOM" label="BOM"></el-option>
                                    <el-option value="NON BOM" label="NON BOM"></el-option>
                                </el-select>

                            </div>
                            <div class="col-md-4">
                                <!-- CATALOG STATUS -->
                                <el-select class="col-md-12 mb-2" style="padding-right:9.5px;padding-left:9.5px;" v-model="data.search.cat_status" multiple collapse-tags filterable remote reserve-keyword :placeholder="tt('catalog_status')" :loading="selectSearch.scsLoading" :automatic-dropdown="false" :remote-method="queryCatalogStatus" clearable>
                                    <el-option v-for="item in optionCatalogStatus" :key="item.code" :value="item.code" :label="item.code + ' - ' + item.description">
                                    </el-option>
                                </el-select>

                                <!-- CATALOG TYPE -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.cat_type" filterable remote reserve-keyword :placeholder="tt('catalog_type')" :loading="selectSearch.sctLoading" :automatic-dropdown="false" :remote-method="queryCatalogType" clearable>
                                    <el-option v-for="item in optionCatalogType" :key="item.code" :value="item.code" :label="item.code + ' - ' + item.description">
                                    </el-option>
                                </el-select>

                                <!-- CATALOGUED BY -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.catalogued_by"
                                    filterable remote reserve-keyword :placeholder="tt('catalogued_by')" :loading="selectSearch.subLoading" :automatic-dropdown="false" :remote-method="queryCataloguedBy" clearable>
                                    <el-option v-for="item in optionCataloguedBy" :key="item.value" :value="item.namecode" :label="item.username + ' - ' + item.name">
                                    </el-option>
                                </el-select>

                                <!-- SHORT DESCRIPTION -->
                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.short_description" :placeholder="tt('short_description')"
                                    clearable></el-input>

                                <!-- KEYWORD -->
                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.keyword" :placeholder="tt('keyword')"></el-input>

                                <div class="demo-input-suffix">
                                    <el-input class="form-control-sm col-md-12 mb-4" v-model="data.search.catalogued_at"
                                        type="date" :placeholder="tt('catalogued_at')" size="big"></el-input>
                                </div>

                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.status_report" :placeholder="tt('status_report')"
                                    size="medium"></el-input>

                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.priority" :placeholder="tt('priority')"
                                    size="medium"></el-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.new_material_number" :placeholder="tt('new_material_number')"
                                    size="medium"></el-input>

                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.long_description" :placeholder="tt('long_description')"
                                    size="medium"></el-input>

                                <!-- Source Description -->
                                <el-input type="text" class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.source_description" :placeholder="tt('source_description')"
                                    size="medium"></el-input>
                            </div>
                        </div>
                        <base-button type="sm" @click="searchMaterial"
                            class="btn btn-block btn-lg btn-primary pt-3 pb-3" size="lg">
                            {{ tt('search') }}
                        </base-button>
                    </div>
                    <noaccess v-else />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Api from '@/helpers/api';
import mcrNumber from '@/services/material/draftList.service';
import itemName from '@/services/dictionary/standardItemName.service';
import groupClass from '@/services/dictionary/natoGroupClass.service';
import materialType from '@/services/master/materialType.service';
import materialGroup from '@/services/master/materialGroup.service';
import manufacture from '@/services/dictionary/manufacture.service';
import companyCode from '@/services/master/companyCode.service';
import plant from '@/services/master/plantCode.service';
import location from '@/services/master/locationCode.service';
import equipmentCode from '@/services/master/equipmentCode.service';
import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
import catalogStatus from '@/services/master/catalogStatus.service';
import catalogType from '@/services/master/catalogType.service';
import updatedBy from '@/services/manage/manageAccount.service';
import WorkbenchMaterial from '@/services/workbench/WorkbenchMaterial.service';

export default {
    watch: {
        $route(to) {
            this.viewType = to.params.type;
        }
    },
    data() {
        return {
            requestCalls: 0,
            data: {
                search: {
                    material_number: '',
                    mcr_number: '',
                    item_name: '',
                    group_class: '',
                    material_type: '',
                    material_group: '',
                    valuation_class: '',
                    stock_type: '',
                    manufacture: '',
                    manufacture_ref: '',
                    client: '',
                    company: '',
                    plant: '',
                    location: '',
                    shelf: '',
                    bin: '',
                    equipment_code: '',
                    scope_of_supply: '',
                    uom: '',
                    fab_non_fab: '',
                    bom_non_bom: '',
                    critical_non_critical: '',
                    keyword: '',
                    date: '',
                    cat_status: '',
                    cat_type: '',
                    catalogued_by: '',
                    catalogued_at: '',
                    item_type: '',

                    status_report: '',
                    priority: '',
                    long_description: '',
                    new_material_number: '',
                    material_type_source: '',
                    material_group_source: '',
                    uom_source: '',
                    valuation_class_source: '',
                    source_description: '',
                    type: 'search-material',
                },
            },
            endUserSearchMaterial: [],
            materialNumber: [],
            mcrNumber: [],
            itemName: [],
            groupClass: [],
            materialType: [],
            materialGroup: [],
            valuationClass: [],
            stockType: [],
            manufacture: [],
            manufactureRef: [],
            client: [],
            companyCode: [],
            plant: [],
            location: [],
            shelf: [],
            bin: [],
            equipmentCode: [],
            scopeOfSupply: [],
            unitOfMeasure: [],
            fabNonFab: [],
            bomNonBom: [],
            criticalNonCritical: [],
            catalogStatus: [],
            catalogType: [],
            updatedBy: [],

            links: [],
            listSelect: [],
            loading: false,
            optionMaterialNumber: [],
            optionItem: [],
            optionCompany: [],
            optionGroupClass: [],
            optionMaterialType: [],
            optionMaterialGroup: [],
            optionManufactureCode: [],
            optionPlant: [],
            optionLocation: [],
            optionEquipmentCode: [],
            optionScopeOfSupply: [],
            optionUnitOfMeasure: [],
            optionCatalogStatus: [],
            optionCatalogType: [],
            optionCataloguedBy: [],
            selectSearch: {
                sitLoading: false,
                sgcLoading: false,
                smtLoading: false,
                smgLoading: false,
                suLoading: false,
                smcLoading: false,
                scmLoading: false,
                splLoading: false,
                slcLoading: false,
                seqLoading: false,
                scsLoading: false,
                sctLoading: false,
                subLoading: false,
                smnLoading: false,
            },
            viewType: this.$route.params.type,
        }
    },
    mounted() {},
    computed: {
        ...mapState({
            authUserPermission: state => state.auth.userPermission
        }),
    },
    methods: {
        searchMaterial() {
            this.$router.push({ name: 'WorkBench', query: this.data.search });
        },
        selectMaterialNumber(item) {
            this.data.search.material_number = item.value
        },
        createFilter(queryString) {
            return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
            };
        },
        queryMaterialNumber(query) {
            if (query.length > 2) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;

                this.selectSearch.smnLoading = true;

                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, WorkbenchMaterial.getMaterialNumber({ search: query })).onSuccess(function (response) {
                        context.optionMaterialNumber = response.data.data;
                        context.selectSearch.smnLoading = false;
                    }).onError(function (error) {
                        context.optionMaterialNumber = [];
                        context.selectSearch.smnLoading = false;
                    }).call();
                }, 200)
            }
        },
        queryItemName(query) {
            if (query.length > 2) {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;

                this.selectSearch.sitLoading = true;

                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, itemName.get(null, { per_page: 'none', item_name: query })).onSuccess(function (response) {
                        context.optionItem = response.data.data.data.data;
                        context.selectSearch.sitLoading = false;
                    }).onError(function (error) {
                        context.optionItem = [];
                        context.selectSearch.sitLoading = false;
                    }).call();
                }, 200)
            }
        },
        queryGroupClass(query) {
            if (query !== '') {
                clearTimeout(this.selectSearch.loadTimeout);
                let context = this;

                this.selectSearch.sgcLoading = true;

                this.selectSearch.loadTimeout = setTimeout(() => {
                    Api(context, groupClass.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                        context.optionGroupClass = response.data.data.data.data;
                        context.selectSearch.sgcLoading = false;
                    }).onError(function (error) {
                        context.optionGroupClass = [];
                        context.selectSearch.sgcLoading = false;
                    }).call();
                }, 200)
            } else {
                this.optionGroupClass = [];
            }
        },
        queryMaterialType(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.smtLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, materialType.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionMaterialType = response.data.data.data.data;
                            context.selectSearch.smtLoading = false;
                        }).onError(function (error) {
                            context.optionMaterialType = [];
                            context.selectSearch.smtLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionMaterialType = [];
            }
        },
        queryMaterialGroup(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.smgLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, materialGroup.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionMaterialGroup = response.data.data.data.data;
                            context.selectSearch.smgLoading = false;
                        }).onError(function (error) {
                            context.optionMaterialGroup = [];
                            context.selectSearch.smgLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionMaterialGroup = []
            }
        },
        queryUnitOfMeasure(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.suLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, unitOfMeasure.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionUnitOfMeasure = response.data.data.data.data;
                            context.selectSearch.suLoading = false;
                        }).onError(function (error) {
                            context.optionUnitOfMeasure = [];
                            context.selectSearch.suLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionUnitOfMeasure = []
            }
        },
        queryManufactureCode(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.smcLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, manufacture.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionManufactureCode = response.data.data.data.data;
                            context.selectSearch.smcLoading = false;
                        }).onError(function (error) {
                            context.optionManufactureCode = [];
                            context.selectSearch.smcLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionManufactureCode = [];
            }
        },
        queryCompany(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.scmLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, companyCode.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionCompany = response.data.data.data.data;
                            context.selectSearch.scmLoading = false;
                        }).onError(function (error) {
                            context.optionCompany = [];
                            context.selectSearch.scmLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionCompany = [];
            }
        },
        queryPlant(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.splLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, plant.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionPlant = response.data.data.data.data;
                            context.selectSearch.splLoading = false;
                        }).onError(function (error) {
                            context.optionPlant = [];
                            context.selectSearch.splLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionPlant = []
            }
        },
        queryLocation(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.slcLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, location.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionLocation = response.data.data.data.data;
                            context.selectSearch.slcLoading = false;
                        }).onError(function (error) {
                            context.optionLocation = [];
                            context.selectSearch.slcLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionLocation = []
            }
        },
        queryEquipmentCode(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.seqLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, equipmentCode.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionEquipmentCode = response.data.data.data.data;
                            context.selectSearch.seqLoading = false;
                        }).onError(function (error) {
                            context.optionEquipmentCode = [];
                            context.selectSearch.seqLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionEquipmentCode = []
            }
        },
        queryCatalogStatus(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.scsLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, catalogStatus.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionCatalogStatus = response.data.data.data.data;
                            context.selectSearch.scsLoading = false;
                        }).onError(function (error) {
                            context.optionCatalogStatus = [];
                            context.selectSearch.scsLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionCatalogStatus = []
            }
        },
        queryCatalogType(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.sctLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, catalogType.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionCatalogType = response.data.data.data.data;
                            context.selectSearch.sctLoading = false;
                        }).onError(function (error) {
                            context.optionCatalogType = [];
                            context.selectSearch.sctLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionCatalogType = []
            }
        },
        queryCataloguedBy(query) {
            if (query !== '') {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);
                    let context = this;

                    this.selectSearch.subLoading = true;

                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, updatedBy.get(null, { per_page: 'none', search: query })).onSuccess(function (response) {
                            context.optionCataloguedBy = response.data.data.data.data;
                            context.selectSearch.subLoading = false;
                        }).onError(function (error) {
                            context.optionCataloguedBy = [];
                            context.selectSearch.subLoading = false;
                        }).call();
                    }, 200)
                }
            } else {
                this.optionCataloguedBy = []
            }
        },
        checkString(elemen, query) {
            var details = [];

            elemen.forEach(function (item, index) {
                // details[index] = item.label.toLowerCase()
                details[index] = {
                    value: item.value,
                    label: item.label.toLowerCase()
                }
            })
            var isPresent;
            var records = [];
            var matchWords = query.toLowerCase().split(" ");

            for (var index = 0; index < details.length; index++) {
                isPresent = true;

                for (var outer = 0; outer < matchWords.length; outer++) {
                    if (details[index].label.indexOf(matchWords[outer]) === -1) {
                        isPresent = false;
                        break;
                    }
                }

                if (isPresent) {
                    records.push(details[index]);
                }
            }
            return records;
        }
    }
};
</script>
<style>
.width {
    width: 95%;
}

.el-input--medium .el-input__inner {
    height: 40px;
    line-height: 40px;
    font-size: 0.897rem;
}

.el-input__inner {
    font-size: 0.897rem;
}
</style>
