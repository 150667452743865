import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('CatalogType?page=' + page, {params});
    }, 
    getPurchaseByCompany() {
        return Service().get('PurchasingByCompany');
    }, 
    show(id) {
        return Service().get('CatalogType/show/'+id);
    },    
    create(params) {
        return Service().post('CatalogType/create', params);
    },
    update(id, params) {
        return Service().post('CatalogType/update/'+id, params);
    },
    delete(id) {
        return Service().post('CatalogType/delete/'+id);
    }
}